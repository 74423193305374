import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import React, { Suspense, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import ReactGA from "react-ga4";
import "./App.css";
import Loader from "./components/Loader/Loader";
import axios from "axios";
import { registerServiceWorker } from "./utils/serviceWorkerUtils";
import { MathJaxContext } from "better-react-mathjax";

const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;

// console.log(publicVapidKey);

//All Components
const BlogsDetails=React.lazy(()=>import("./pages/Blogs/BlogsDetails"))
const LabPage=React.lazy(()=>import("./pages/LabsPage/LabsPage"))
const LabDetails=React.lazy(()=>import("./pages/LabsPage/LabDetails"))
const LearnMobile=React.lazy(()=>import("./pages/LearnPage/LearnMobile"))
const BlogsMobile=React.lazy(()=>import("./pages/Blogs/BlogsMobile"))
const BlogCategory=React.lazy(()=>import("./pages/Blogs/BlogCategory"))
const LearnDetailsMobile=React.lazy(()=>import("./pages/LearnPage/LearnDetailsMobile"))
const LoginForm = React.lazy(() => import("./components/Auth/Login/LoginForm"));
const AchievementDetails = React.lazy(() =>
  import("./pages/AchievementDetails/AchievementDetailsMobile")
);
const SignupForm = React.lazy(() =>
  import("./components/Auth/Signup/SignupForm")
);
const LandingPage = React.lazy(() =>
  import("./components/LandingPage/LandingPage")
);
const Skills = React.lazy(() => import("./components/Skills/Skills"));
const SkillDetails = React.lazy(() =>
  import("./components/SkillDetails/SkillDetails")
);
const Wrapper = React.lazy(() => import("./components/Wrapper/Wrapper"));
const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const EditProfileForm = React.lazy(() =>
  import("./components/EditProfileForm/EditProfileForm")
);
const Mission = React.lazy(() => import("./components/Mission/Mission"));
const ExperimentComponent = React.lazy(() =>
  import("./components/ExperimentComponent/ExperimentComponent")
);
const Labs = React.lazy(() => import("./components/Labs/Labs"));
const War = React.lazy(() => import("./components/War/War"));
const Battle = React.lazy(() => import("./components/Battle/Battle"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const TransactionComponent = React.lazy(() =>
  import("./components/Transaction/TransactionComponent")
);
const FollowFollowing = React.lazy(() =>
  import("./components/FollowFollowing/FollowFollowing")
);
const HelpPageComponent = React.lazy(() =>
  import("./components/HelpPageComponent/HelpPageComponent")
);
const Report = React.lazy(() => import("./components/Report/Report"));
const ForgotPasswordComponent = React.lazy(() =>
  import("./pages/Passwords/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/ResetPassword/ResetPassword")
);
const Challenges = React.lazy(() =>
  import("./components/Challenges/Challenges")
);
const Rewards = React.lazy(() => import("./components/Rewards/Rewards"));
const OrderComponent = React.lazy(() =>
  import("./components/OrderComponent/OrderComponent")
);
const OrderDetails = React.lazy(() =>
  import("./components/OrderComponent/OrderDetails")
);
const CartComponent = React.lazy(() =>
  import("./components/CartPageComponent/CartComponent")
);
const SummerCamp = React.lazy(() => import("./pages/SummerCamp/SummerCamp"));
const NotificationPage = React.lazy(() =>
  import("./components/NotificationPage/NotificationPage")
);
const QRCode = React.lazy(() => import("./pages/QRCode/QRCode"));
const GraphMobile = React.lazy(() => import("./pages/GraphPage/GraphMobile"));
const LevelUpMobile = React.lazy(() =>
  import("./components/LevelUp/LevelUpMobile")
);
const Specialization = React.lazy(() =>
  import("./pages/Specialization/Specialization")
);
const AnimatedRoutes = React.lazy(() =>
  import("./components/AnimatedRoutes/AnimatedRoutes")
);
const HowItWorksMobile = React.lazy(() =>
  import("./pages/HowItWorks/HowItWorksMobile")
);
const OurStoryMobile = React.lazy(() =>
  import("./pages/OurStory/OurStoryMobile")
);

const config = {
  loader: { load: ["input/asciimath"] },
  options: {
    // enableMenu: true,
    menuOptions: {
      settings: {
        inTabOrder: false,
      },
    },
  },
};

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  // console.log(decodedToken);

  const isAuthenticated = token && decodedToken;
  const navigate = useNavigate();
  const [hasLeveledDown, setHasLevelledDown] = useState(false);
  const [popup, setPopup] = useState("");
  // console.log(hasLeveledDown);

  const checkLeveldown = async () => {
    if (isAuthenticated) {
      const userId = localStorage.getItem("userId");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/popups/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data.data[0]);
        if (response.data.data.length === 0) {
          throw new Error("No User level data found");
        }
        const level = response.data.data[0].level;
        localStorage.setItem("level", level);
        const notCompletedStreak = response.data.data[0].not_completed_streak;
        const hasSeenLd = response.data.data[0].has_seen_ld;
        // console.log(notCompletedStreak, hasSeenLd);
        if (
          notCompletedStreak !== 0 &&
          notCompletedStreak % 3 === 0 &&
          hasSeenLd === 0
        ) {
          setHasLevelledDown(true);
          const name = localStorage.getItem("username");
          setPopup(
            <LevelUpMobile
              currLevel={level}
              prevLevel={9999}
              name={name}
              setShowDialog={setHasLevelledDown}
            />
          );
          const putResponse = await axios.patch(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/popups/${userId}`
          );
          if (!putResponse.data.message) {
            throw new Error("Error updating has user viewed level down");
          }
        }
      } catch (error) {
        console.log("Error fetching user level data for popup", error);
      }
    }
  };



  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/");
    }
    checkLeveldown();
  }, []);

  return (
    <>
      {hasLeveledDown && (
        <Suspense fallback={<Loader />}>
          {popup}
        </Suspense>
      )}
      {!hasLeveledDown && children}
    </>
  );
};


const UnProtectedRoute=({children})=>{
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  const navigate=useNavigate()
  useEffect(() => {
    if (token && decodedToken) {
      return navigate("/dashboard");
    }
  }, [token, decodedToken, navigate]);

  return(
    <>
    {children}
    </>
    )
}

function App() {
  const [testRender, setTestRender] = useState(null);

  console.log(testRender && process.env.REACT_APP_BUILD_ENV === "LOCAL");



  const importTestComponent = () => {
    if (process.env.REACT_APP_BUILD_ENV === "LOCAL") {
      import("./testComponents/Crontester")
        .then((module) => {
          const Crontester = module.default;
          setTestRender(<Crontester />);
        })
        .catch((error) => {
          console.error("Failed to load Crontester module", error);
          // Handle error if necessary
        });
    }
  };

  const subscribeToNotification = async () => {
    console.log("Inside subscribe to notification");

    try {
      // const registration = ServiceWorkerContainer.controller;
      console.log("saddasdsd");
      if ("serviceWorker" in navigator) {
        const registration = await navigator.serviceWorker.ready;
        console.log(registration, "fhsaofhosahfioaf");

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicVapidKey,
        });
        console.log(subscription);

        // const result = await axios.post(
        //   `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/notification/subscribe`,
        //   {
        //     method: "POST",
        //     body: JSON.stringify(subscription),
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //   }
        // );
      } else {
        console.log("Browser doesn't support serviceworker");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    importTestComponent();
    registerServiceWorker(
      () => {},
      () => {},
      publicVapidKey
    );
    // subscribeToNotification()
  }, []);

  return (
    <MathJaxContext config={config} >
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <UnProtectedRoute>
              <LandingPage />
              </UnProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <UnProtectedRoute>
              <LoginForm />
              </UnProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <UnProtectedRoute>
              <SignupForm />
              </UnProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/signup/:refCode"
          element={
            <Suspense fallback={<Loader />}>
              <UnProtectedRoute>
              <SignupForm />
              </UnProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/skills"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute>
                <Skills />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/skills/:skillId/stages"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <SkillDetails />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/skills/:skillId/specialization/:specId/stages"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <SkillDetails />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/skills/:skillId/specialization/:specId/stages/:stageId/mission/:missionId"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <Mission />
              </ProtectedRoute>
            </Suspense>
          }
        />
        {/* <Route
          path="/home"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <Wrapper />
              </ProtectedRoute>
            </Suspense>
          }
        /> */}
        <Route
          path="/profile/:userId"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/edit-profile"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <EditProfileForm />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/labs"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <Labs />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/skills/:skillId/stages/:stageId/mission/:missionId"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <Mission />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/labs/:labId/experiments"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <ExperimentComponent />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/skills/:skillId/war/:warId"
          element={
            <Suspense fallback={<Loader />}>
              {" "}
              <ProtectedRoute>
                <War />
              </ProtectedRoute>
            </Suspense>
          }
        />
          <Route
            path="/skills/:skillId/specialization/:specId/war/:warId"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <War />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/skills/:skillId/stages/:stageId/battle/:battleId"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <Battle />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/skills/:skillId/specialization/:specId/stages/:stageId/battle/:battleId"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <Battle />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/transactions"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <TransactionComponent />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/follow/:userId"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <FollowFollowing />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/help"
            element={
              <Suspense fallback={<Loader />}>
                <HelpPageComponent />
              </Suspense>
            }
          />
          <Route
            path="/labs/:labId/experiments/report/:attemptId"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <Report />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<Loader />}>
                <ForgotPasswordComponent />
              </Suspense>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <ResetPassword />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/challenges"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <Challenges />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/notifications"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <NotificationPage />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/rewards"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <Rewards />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/orders"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <OrderComponent />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/order-details/:transactionId"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <OrderDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/cart"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <CartComponent />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/summercamp2024"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <SummerCamp />
              </Suspense>
            }
          />
          <Route
            path="/qr-code/*"
            element={
              <Suspense fallback={<Loader />}>
                <QRCode />
              </Suspense>
            }
          />
          <Route
            path="/graph/:userId"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <GraphMobile />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/specialization/:specId/*"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute>
                  <Specialization />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/how-it-works"
            element={
              <Suspense fallback={<Loader />}>
                <HowItWorksMobile />
              </Suspense>
            }
          />
          <Route
            path="/blogs/category/:catId"
            element={
              <Suspense fallback={<Loader />}>
                <BlogCategory />
              </Suspense>
            }
          />
          <Route
            path="/blogs/details/:blogId"
            element={
              <Suspense fallback={<Loader />}>
                <BlogsDetails />
              </Suspense>
            }
          />
          <Route
            path="/blogs"
            element={
              <Suspense fallback={<Loader />}>
                <BlogsMobile />
              </Suspense>
            }
          />
          <Route path="/learn" element={<Suspense fallback={<Loader />} ><LearnMobile /></Suspense>} />
          <Route path="/learn/:title" element={<Suspense fallback={<Loader/>}> <LearnDetailsMobile /> </Suspense>} />
          <Route path="/lab-page" element={<Suspense fallback={<Loader/>}><LabPage /></Suspense>} />
          <Route path="/lab-page/:title" element={<Suspense fallback={<Loader/>}><LabDetails /></Suspense>} />
          <Route
            path="/ourStory"
            element={
              <Suspense fallback={<Loader />}>
                <OurStoryMobile />
              </Suspense>
            }
          />
          {testRender && process.env.REACT_APP_BUILD_ENV === "LOCAL" && (
            <Route path="/test" element={testRender} />
          )}
          <Route
            path="/achievement-details"
            element={
              <Suspense fallback={<Loader />}>
                {" "}
                <ProtectedRoute>
                  <AchievementDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
        </Routes>
        <AnimatedRoutes />
      </Router>
    </MathJaxContext>
  );
}

export default App;
